<template>
    <div>
        <h3 class="text-center my-4">Catégorie des livres</h3>

        <table-liste-simple
            :items="items"
            :headers="headers"
            tableUrl="category-livre"
            @deleteElement="get"
        />
    </div>
</template>

<script>
import TableListeSimple from "../global/TableListeSimple.vue";
export default {
    components: { TableListeSimple },
    mounted() {
        this.get();
    },
    methods: {
        get() {
            this.getData("category-livre")
                .then((response) => {
                    this.$store.commit("setStateData", {
                        key: "categoryLivre",
                        value: response.data.data,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    computed: {
        items() {
            return this.$store.getters.fetchData("categoryLivre");
        },
        headers() {
            return [
                {
                    text: "#",
                    value: "id",
                },
                {
                    text: "name",
                    value: "name",
                },
                {
                    text: "description",
                    value: "description",
                },
                {
                    text: "faculte_id",
                    value: "faculte_id",
                },
                {
                    text: "opération",
                    value: "operation",
                },
            ];
        },
    },
};
</script>

<style scoped></style>
