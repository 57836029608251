<template>
    <div>
        <h3 class="text-cente">Faculte</h3>
        <facult-list />
    </div>
</template>

<script>
import FacultList from "../components/facultes/FacultList.vue";
export default {
    components: { FacultList },
};
</script>

<style lang="scss" scoped></style>
