<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-green rounded-bottom">
        <div class="container-fluid">
            <button
                class="navbar-toggler"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <i class="fas fa-bars"></i>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <div class="navbar-brand" href="#">
                    <h2 class="text-white">My Green Library</h2>
                </div>
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item"></li>
                    <li class="nav-item"></li>
                    <li class="nav-item">
                        <router-link to="/">
                            <a class="nav-link">Accueil</a>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/find-book">
                            <a class="nav-link">Retrouvez les livres ici </a>
                        </router-link>
                    </li>
                </ul>
            </div>

            <div class="d-flex align-items-center">
                <router-link to="login" v-if="!isLogged">
                    <a
                        class="nav-link px-2 links"
                        style="
                            color: green;
                            background-color: white;
                            padding: 3px;
                            border-radius: 3px;
                        "
                    >
                        <i class="fas fa-lock"></i>
                        Connexion
                    </a>
                </router-link>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    computed: {
        isLogged() {
            return this.$store.getters.user;
        },
    },
};
</script>

<style scoped>
.nav-sec {
    display: flex;
    justify-content: space-between;
}
header {
    /* background-color: #237443;*/
    background-color: #399e4f;
    color: #fff;
}
nav {
    text-align: right;
    background: #237641;
}
a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
}
.active {
    background-color: #35495e;
}
.active {
    background-color: #41b883;
}

nav a:hover,
nav a.router-link-active,
nav a.exact-active {
    color: green;
    background-color: #fff;
    cursor: pointer;
    border-radius: 5px;
}
</style>
