<template>
    <div>
        <h4>Faculté</h4>

        <form action="">
            <div class="form-group">
                <label for="">Nom de la Faculté</label>
                <input
                    class="form-control form-control-sm"
                    type="text"
                    v-model="form.name"
                />
            </div>
            <div class="form-group">
                <label for="">Université</label>
                <select
                    name=""
                    id=""
                    v-model="form.university_id"
                    class="form-control"
                >
                    <option value=""></option>
                    <option v-for="un in universities" :value="un.id">
                        {{ un.name }}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label for="">Déscription</label>
                <textarea
                    class="form-control form-control-sm"
                    name=""
                    v-model="form.description"
                ></textarea>
            </div>
            <div v-if="errorMessage" class="text-danger">
                {{ errorMessage }}
            </div>

            <div class="d-flex align-items-center justify-content-center my-3">
                <button @click.prevent="save" class="btn btn-success">
                    <span> Enregistrer </span>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                name: "",
                description: "",
                university_id: "",
            },
            errorMessage: "",
            universities: "",
        };
    },
    watch: {},
    mounted() {
        this.getData("university")
            .then((result) => {
                this.universities = result.data.data;

                this.$emit("close");
            })
            .catch((e) => {
                console.log(e);
            });
    },
    methods: {
        save() {
            if (!this.form.university_id) {
                this.form.university_id =
                    this.$store.state.user.user.university_id;
            }

            this.postData("faculte", this.form)
                .then((response) => {
                    this.$emit("close");
                    this.form = {};
                })
                .catch((error) => {
                    this.errorMessage = error.response.data.message;
                });
        },
    },
    computed: {},
};
</script>

<style lang="scss" scoped></style>
