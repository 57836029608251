<template>
    <div>
        <h3 class="text-center my-4">Livre</h3>
        <!-- <upload-image/> -->
        <modal-component :modalActive="isShowForm" @close="showForm">
            <add-livre @success="success"></add-livre>
        </modal-component>
        <table-liste-simple
            @reload="reload"
            :items="items"
            :headers="headers"
            @addElement="showForm"
        />
    </div>
</template>

<script>
import ModalComponent from "../global/ModalComponent.vue";

import AddLivre from "./AddLivre.vue";
import TableListeSimple from "./TableListeSimple.vue";

export default {
    components: { ModalComponent, AddLivre, TableListeSimple },
    data() {
        return {
            isShowForm: false,
        };
    },
    mounted() {
        this.get();
    },
    methods: {
        reload() {
            this.get();
        },
        showForm() {
            this.isShowForm = !this.isShowForm;
        },
        success() {
            this.showForm();
            this.get();
        },
        get() {
            this.getData("livre")
                .then((response) => {
                    this.$store.commit("setStateData", {
                        key: "livre",
                        value: response.data.data,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    computed: {
        items() {
            return this.$store.getters.fetchData("livre");
        },
        headers() {
            return [
                {
                    text: "#",
                    value: "id",
                },
                {
                    text: "title",
                    value: "title",
                },
                {
                    text: "description",
                    value: "description",
                },
                {
                    text: "faculté",
                    value: "faculte_id",
                },
                {
                    text: "Livre",
                    value: "file_url",
                },
                {
                    text: "opération",
                    value: "operation",
                },
            ];
        },
    },
};
</script>

<style scoped></style>
