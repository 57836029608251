<template>
    <div>
        <modal-component
            v-if="isShowForm"
            :modalActive="isShowForm"
            @close="showForm"
        >
            <university-form
                @success="success"
                :selectUniversity="selectUniversity"
            />
        </modal-component>
        <table-liste-simple
            :items="universities"
            :headers="headers"
            @addElement="showForm"
            tableUrl="university"
            @deleteElement="get"
            @editElement="editElement"
        />
    </div>
</template>

<script>
import ModalComponent from "../global/ModalComponent.vue";
import TableListeSimple from "../global/TableListeSimple.vue";
import UniversityForm from "./UniversityForm.vue";
export default {
    components: { TableListeSimple, UniversityForm, ModalComponent },
    data() {
        return {
            isShowForm: false,
            selectUniversity: null,
        };
    },
    mounted() {
        this.get();
    },
    methods: {
        editElement(item) {
            this.isShowForm = true;
            this.selectUniversity = item;
        },
        success() {
            this.isShowForm = false;
            this.selectUniversity = {};
            this.get();
        },
        showForm() {
            this.isShowForm = !this.isShowForm;
        },
        get() {
            this.getData("university")
                .then((response) => {
                    this.$store.commit("setStateData", {
                        key: "university",
                        value: response.data.data,
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    },
    computed: {
        universities() {
            return this.$store.getters.fetchData("university");
        },
        headers() {
            return [
                {
                    text: "Id",
                    value: "id",
                    sortable: true,
                },
                {
                    text: "Name",
                    value: "name",
                    sortable: true,
                },
                {
                    text: "Description",
                    value: "description",
                    sortable: true,
                },
                {
                    text: "Addresse",
                    value: "addresses",
                    sortable: true,
                },
                {
                    text: "Operation",
                    value: "operation",
                },
            ];
        },
    },
};
</script>

<style scoped></style>
