<template>
    <div>
        <h3>Ajouter un Livre</h3>
        <form action="">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Titre</label>
                        <input
                            class="form-control form-control-sm"
                            type="text"
                            v-model="form.title"
                        />
                    </div>
                    <div class="form-group">
                        <label for="">Auteur</label>
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="form.author"
                        />
                    </div>
                    <div>
                        <label for="">Déscription</label>
                        <textarea
                            class="form-control form-control-sm"
                            v-model="form.description"
                        ></textarea>
                    </div>
                    <div>
                        <label for="">University</label>
                        <select
                            class="form-control form-control-sm"
                            name=""
                            id=""
                            v-model="form.university_id"
                        >
                            <option
                                v-for="item in universities"
                                :key="item.id"
                                v-bind:value="item.id"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                    
                </div>
                <div class="col-md-6">
                    <div>
                        <label for="">Département</label>
                        <select
                            class="form-control form-control-sm"
                            name=""
                            id=""
                            v-model="form.department_id"
                        >
                            <option value=""></option>
                            <option
                                v-for="item in departments"
                                :key="item.id"
                                v-bind:value="item.id"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                   
             
                    <div>
                        <label for="">Tags</label>
                        <textarea
                            class="form-control form-control-sm"
                            name=""
                            v-model="form.tags"
                        ></textarea>
                    </div>
                </div>
            </div>
            <div class="errorMessage">
                <p>{{ errors.message }}</p>
            </div>
            <div class="d-flex align-items-center justify-content-center my-3">
                <button @click.prevent="saveData" class="btn btn-success">
                    <span>
                        Enregistrer
                    </span>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                title: "",
                author: "",
                description: "",
                file_url: "",
                tags: "",
                niveau_id: "",
                faculte_id: "",
                institut_id: "",
                department_id: "",
                category_livre_id: "",
                university_id: "",
            },
            livre: "",
            errors: [],
        };
    },
    mounted() {
        this.initialize();
    },
    methods: {
        saveData() {
            if (!this.form.university_id) {
                this.form.university_id =
                    this.$store.state.user.user.university_id;
            }
            const formData = new FormData();
            formData.append("livre", this.livre);
            formData.append("title", this.form.title);
            formData.append("author", this.form.author);
            formData.append("description", this.form.description);
            formData.append("file_url", this.form.file_url);
            formData.append("tags", this.form.tags);
            formData.append("niveau_id", this.form.niveau_id);
            formData.append("faculte_id", this.form.faculte_id);
            formData.append("institut_id", this.form.institut_id);
            formData.append("department_id", this.form.department_id);
            formData.append("university_id", this.form.university_id);
            formData.append("category_livre_id", this.form.category_livre_id);

            this.postData("livre", formData)
                .then((response) => {
                    console.log(response);
                    this.form = {};
                    this.$emit("success");
                })
                .catch((error) => {
                    console.log(error);
                    this.errors = error.response.data;
                });
            // const headers = { 'Content-Type': 'multipart/form-data' };
            // axios.post('https://httpbin.org/post', formData, { headers }).then((res) => {
            //     res.data.files; // binary representation of the file
            //     res.status; // HTTP status
            // });
        },
        initialize() {
            this.getData("university")
                .then((response) => {
                    this.$store.commit("setStateData", {
                        key: "university",
                        value: response.data.data,
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
            this.getData("faculte")
                .then((response) => {
                    this.$store.commit("setStateData", {
                        key: "faculte",
                        value: response.data.data,
                    });
                })
                .catch((error) => {
                    console.error(error);
                });

            this.getData("institut")
                .then((response) => {
                    this.$store.commit("setStateData", {
                        key: "institut",
                        value: response.data.data,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
            this.getData("niveau")
                .then((response) => {
                    this.$store.commit("setStateData", {
                        key: "niveau",
                        value: response.data.data,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });

            this.getData("department")
                .then((result) => {
                    this.$store.commit("setStateData", {
                        key: "department",
                        value: result.data.data,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });

            this.getData("category-livre")
                .then((response) => {
                    this.$store.commit("setStateData", {
                        key: "categoryLivre",
                        value: response.data.data,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        uploadFile() {
            this.livre = this.$refs.file.files[0];
        },
    },
    computed: {
        universities() {
            return this.$store.getters.fetchData("university");
        },
        facultes() {
            return this.$store.getters.fetchData("faculte");
        },
        departments() {
            return this.$store.getters.fetchData("department");
        },
        instituts() {
            return this.$store.getters.fetchData("institut");
        },
        categoryLivres() {
            return this.$store.getters.fetchData("categoryLivre");
        },
        niveaus() {
            return this.$store.getters.fetchData("niveau");
        },
    },
};
</script>

<style lang="scss" scoped></style>
