<template>
    <div>
        <form action="">
            <div class="form-group">
                <label for="">Nom de l'Université</label>
                <input
                    class="form-control form-control-sm"
                    type="text"
                    v-model="form.name"
                />
            </div>
            <div>
                <label for="">Adresse</label>
                <input
                    class="form-control form-control-sm"
                    type="text"
                    v-model="form.addresses"
                />
            </div>
            <div class="form-group">
                <label for="">Déscription</label>
                <textarea
                    class="form-control form-control-sm"
                    name=""
                    id=""
                    v-model="form.description"
                    cols="60"
                    rows="5"
                ></textarea>
            </div>
            <div class="d-flex align-items-center justify-content-center my-3">
                <button @click.prevent="saveData" class="btn btn-success">
                    <div class="" v-if="university_id">
                        <span>Modifier</span>
                    </div>
                    <div class="" v-else>
                        <span class="btn-sm btn-success">
                            Enregistrer
                        </span>
                    </div>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    props: ["selectUniversity"],
    data() {
        return {
            form: {
                name: "",
                description: "",
                addresses: "",
            },
            university_id: null,
        };
    },
    mounted() {
        if (this.selectUniversity) {
            this.form.name = this.selectUniversity.name;
            this.form.description = this.selectUniversity.description;
            this.form.addresses = this.selectUniversity.addresses;
            this.university_id = this.selectUniversity.id;
        }
    },
    methods: {
        saveData() {
            if (this.university_id) {
                this.putData(`university/${this.university_id}`, this.form)
                    .then((response) => {
                        console.log(response);
                        this.form = {};
                        this.university_id = null;
                        this.$emit("success");
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            } else {
                this.postData("university", this.form)
                    .then((response) => {
                        console.log(response);
                        this.form = {};
                        this.$emit("success");
                    })
                    .catch((e) => {
                        console.log(e);
                    });

                this.university_id = null;
            }
        },
    },
};
</script>

<style scoped></style>
